import Close from '@/assets/close.svg';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import Location from '@/assets/location.svg';
import classNames from 'classnames';
import { AnimatePresence, motion, useMotionValue, useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { Button, ModalPortal, ResponsiveImage } from '..';

const PeopleModal = ({ data, isOpen, onClose }) => {
  const { t } = useTranslation('common');
  const [showAllLodges, setShowAllLodges] = useState(false);
  const [showScrollBar, setShowScrollBar] = useState(false);
  const [dragging, setDragging] = useState(false);
  const fullBioScrollProgress = useMotionValue(0);
  const y = useMotionValue(0);
  const scrollOffset = useMotionValue(0);

  useMotionValueEvent(fullBioScrollProgress, 'change', (latest) => {
    if (!dragging) {
      y.set(latest);
    }
  });

  return (
    <ModalPortal>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={{
              initial: {
                opacity: 0,
              },
              active: {
                opacity: 1,
              },
              exit: {
                opacity: 0,
              },
            }}
            initial="initial"
            animate="active"
            exit="exit"
            transition={{ duration: 0.4 }}
            className="disable-scroll fixed inset-0 z-[51] flex h-screen w-full items-center bg-black/75 p-6 pb-[21px] supports-[height:100dvh]:h-dvh md:px-10"
          >
            <motion.div
              variants={{
                initial: {
                  opacity: 0,
                  y: 50,
                },
                active: {
                  opacity: 1,
                  y: 0,
                },
                exit: {
                  opacity: 0,
                },
              }}
              transition={{ type: 'spring', damping: 20, stiffness: 120, delay: 0.2 }}
              className="person-modal-scrollbar relative flex max-h-full w-full grow gap-[19px] bg-off-white px-xs py-10 max-md:flex-col max-md:overflow-y-auto max-md:overscroll-none max-md:scrollbar-thin max-md:scrollbar-track-black/5 max-md:scrollbar-thumb-black/25 md:aspect-[754/566] md:max-h-[566px] md:gap-8 md:px-10 md:py-16 lg:m-auto lg:aspect-auto lg:max-h-[600px] lg:w-full lg:max-w-[1128px] lg:gap-[94px] lg:pt-10"
            >
              <button
                className="btn icon dark absolute right-xs top-xs size-12 bg-white/90 p-0 md:right-6 md:top-6"
                onClick={onClose}
              >
                <Close role="presentation" viewBox="0 0 12 12" width="14" height="14" aria-label="Close" />
              </button>

              {showScrollBar && (
                <CustomScrollbar setDragging={setDragging} y={y} handleDrag={(val) => scrollOffset.set(val)} />
              )}

              <div className="flex flex-col">
                <div className="size-[168px] shrink-0 md:h-[200px] md:w-[216px]">
                  <ResponsiveImage
                    image={data.image}
                    widths={{ xs: 168, sm: 168, md: 216, lg: 216, xl: 216, xxl: 216 }}
                    heights={{ xs: 168, sm: 168, md: 200, lg: 200, xl: 200, xxl: 200 }}
                  />
                </div>
                <div
                  data-lenis-prevent
                  className="lodges-section h-full flex-col overflow-y-auto max-md:hidden md:mt-4 md:flex lg:mt-[30px]"
                >
                  <motion.div
                    className="person-modal-scrollbar flex flex-col gap-1"
                    variants={{
                      hidden: { height: data.lodges.length > 4 ? 140 : 'fit-content', overflowY: 'hidden' },
                      show: { height: 'auto', overflowY: 'auto' },
                    }}
                    initial="hidden"
                    animate={showAllLodges ? 'show' : 'hidden'}
                    transition={{ type: 'spring', duration: 0.6, damping: 20 }}
                  >
                    {data.lodges.map((lodge, index) => {
                      return (
                        <div
                          key={`lodge-${index}`}
                          className="flex h-8 w-fit items-center gap-2 rounded-full bg-tan px-4 py-3"
                        >
                          <Location className="[&_path]:fill-black" />
                          <p className="text-tiny-paragraph font-n-light leading-tiny-paragraph">{lodge}</p>
                        </div>
                      );
                    })}
                  </motion.div>
                  {data.lodges.length > 4 && (
                    <button
                      className="btn w-fit shrink-0 gap-2.5 md:mt-1"
                      onClick={() => setShowAllLodges((prev) => !prev)}
                    >
                      {t('general.$viewAll')}
                      <DropdownArrow
                        className={classNames('stroke-black transition-transform duration-500', {
                          'rotate-180': showAllLodges,
                        })}
                        role="presentation"
                      />
                    </button>
                  )}
                </div>
              </div>

              <div className="flex flex-col items-start gap-6 md:gap-8 lg:mt-8 lg:h-fit lg:w-[664px] lg:gap-10">
                <div className="heading-section">
                  {data.title && (
                    <p className="mb-3 text-small-paragraph font-n-light leading-desktop-small text-black/75">
                      {data.title}
                    </p>
                  )}
                  {data.headingTitle && (
                    <h1 className="font-larken text-2xl font-light leading-7 md:text-desktop-medium md:leading-desktop-medium">
                      {data.headingTitle}
                    </h1>
                  )}
                </div>

                <div className="lodges-section md:hidden">
                  <motion.div
                    className="flex flex-col gap-1 overflow-hidden"
                    variants={{
                      hidden: {
                        height: data.lodges.length > 4 ? 140 : 'fit-content',
                      },
                      show: {
                        height: 'auto',
                      },
                    }}
                    initial="hidden"
                    animate={showAllLodges ? 'show' : 'hidden'}
                    transition={{ type: 'spring', duration: 0.6, damping: 20 }}
                  >
                    {data.lodges.map((lodge, index) => {
                      return (
                        <div
                          key={`lodge-${index}`}
                          className="flex h-8 w-fit items-center gap-2 rounded-full bg-tan px-4 py-3"
                        >
                          <Location className="[&_path]:fill-black" />
                          <p className="text-tiny-paragraph font-n-light leading-tiny-paragraph">{lodge}</p>
                        </div>
                      );
                    })}
                  </motion.div>
                  {data.lodges.length > 4 && (
                    <button className="btn w-fit gap-2.5" onClick={() => setShowAllLodges((prev) => !prev)}>
                      {t('general.$viewAll')}
                      <DropdownArrow
                        className={classNames('stroke-black transition-transform duration-500', {
                          'rotate-180': showAllLodges,
                        })}
                        role="presentation"
                      />
                    </button>
                  )}
                </div>

                {data.actions && data.actions.length > 0 && (
                  <div className="w-full lg:-mt-1.5">
                    <p className="mb-4 font-gotham text-paragraph font-n-light leading-none">
                      {t('general.$adventures')}
                    </p>
                    <div className="actions-section grid w-full grid-cols-2 justify-between md:grid-cols-[repeat(4,auto)] md:justify-start md:gap-x-4 lg:-mt-1.5 lg:h-10 lg:grid-cols-[repeat(5,auto)] lg:gap-x-6">
                      {data.actions.map((action, index) => (
                        <div
                          key={`action-${index}`}
                          className="flex items-center gap-2.5 pl-[3px] font-gotham text-small-paragraph font-s-light leading-6 text-black/75 before:size-1.5 before:shrink-0 before:rotate-45 before:bg-black/75 before:content-[''] md:w-fit md:gap-2"
                        >
                          {action}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {data.fullBio !== '' && (
                  <FullBioSection
                    fullBio={data.fullBio}
                    setShowScrollBar={setShowScrollBar}
                    fullBioScrollProgress={fullBioScrollProgress}
                    scrollOffset={scrollOffset}
                  />
                )}
                {data.primaryCta && (
                  <Button
                    link={{ ...data.primaryCta, text: t('general.$fullProfile') }}
                    className="btn secondary dark shrink-0 md:w-fit"
                  />
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
};

export default PeopleModal;

const FullBioSection = ({ fullBio, setShowScrollBar, fullBioScrollProgress, scrollOffset }) => {
  const fullBioRef = useRef(null);
  const { scrollYProgress } = useScroll({ container: fullBioRef });
  const y = useTransform(scrollYProgress, [0, 1], [0, 133]);
  const dragY = useTransform(scrollOffset, [0, 133], [0, 1]);

  useMotionValueEvent(y, 'change', (latest) => {
    fullBioScrollProgress.set(latest);
  });

  useMotionValueEvent(dragY, 'change', (latest) => {
    fullBioRef.current.scrollTop = (fullBioRef.current.scrollHeight - 123) * latest;
  });

  useEffect(() => {
    if (fullBioRef?.current) {
      if (fullBioRef?.current.scrollHeight > fullBioRef?.current.clientHeight) {
        setShowScrollBar(true);
      }
    }
  }, [setShowScrollBar]);

  return (
    <motion.div
      ref={fullBioRef}
      data-lenis-prevent
      className="bio-section h-full max-h-full overflow-y-auto font-gotham text-small-paragraph font-s-light leading-6 scrollbar-hide lg:-mt-1.5 lg:h-[123px] [&_p:empty]:hidden [&_p:has(strong)]:mb-[0.55rem] [&_p:last-child]:mb-0 [&_p:not(:has(strong))]:text-black/50 [&_p]:mb-[19px] [&_strong]:text-paragraph [&_strong]:font-n-light"
      dangerouslySetInnerHTML={{ __html: fullBio.replaceAll('<p>&nbsp;</p>', '') }}
    />
  );
};

const CustomScrollbar = ({ y, handleDrag, setDragging }) => {
  const scrollBarRef = useRef(null);

  return (
    <motion.div
      ref={scrollBarRef}
      className="absolute right-1.5 top-1/2 h-[419px] w-1.5 -translate-y-1/2 rounded-full bg-black/5 scrollbar max-md:hidden"
    >
      <motion.div
        className="scrollbar-thumb relative h-[286px] w-1.5 rounded-full bg-black/25"
        drag="y"
        dragConstraints={{ top: 0, bottom: 133 }}
        dragElastic={false}
        dragMomentum={false}
        style={{ y }}
        onDragStart={() => setDragging(true)}
        onDrag={() => {
          handleDrag(y.get());
        }}
        onDragEnd={() => setDragging(false)}
      ></motion.div>
    </motion.div>
  );
};
