import { useEffect, useRef, useState } from 'react';
import { CarouselPagination, HeadingTag, Button, ResponsiveImage } from '@/components';
import classNames from 'classnames';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import tailwindConfig from '@/tailwind.config';
import { useTranslation } from 'next-i18next';

const SeasonCard = ({ season, seasonsCount, dark, hideCaptions = false }) => {
  const [panelsPerView, setPanelsPerView] = useState(1);
  const [align, setAlign] = useState('center');
  const carousel = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    const resize = () => {
      setAlign('prev');
      if (window.innerWidth < parseInt(tailwindConfig.theme.screens.sm, 10)) {
        setAlign('center');
        setPanelsPerView(1.7);
      } else if (window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10)) {
        setPanelsPerView(2);
      } else {
        setPanelsPerView(seasonsCount > 2 ? 1 : 2);
      }
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('flex h-full w-full flex-col items-center justify-between lg:min-h-[660px]')}>
      <div className="flex flex-col gap-s lg:gap-10">
        <HeadingTag
          data={{ htag: '', heading: season.title }}
          className={classNames(
            'text-center font-larken text-2xl font-light leading-7 md:text-desktop-medium md:leading-desktop-medium',
            dark ? 'text-white' : 'text-black',
          )}
        />
        <p
          dangerouslySetInnerHTML={{ __html: season.description }}
          className={classNames(
            'mx-auto mb-s max-w-[310px] text-center font-gotham text-sm font-s-light leading-6 sm:mb-[42px] lg:mb-10',
            dark ? 'text-white/75' : 'text-black/75',
          )}
        />
      </div>

      <div className="flex w-full flex-col items-center">
        <div
          className={classNames(
            'relative block w-full sm:w-[468px]',
            seasonsCount <= 2 ? 'lg:w-[468px]' : 'lg:aspect-[227/304] lg:w-[227px]',
          )}
        >
          <Flicking
            ref={carousel}
            align={align}
            bound={align === 'prev'}
            horizontal
            preventDefaultOnDrag
            resizeOnContentsReady
            panelsPerView={panelsPerView}
          >
            {season.cardRow.cards.map((card, index) => (
              <div key={`${season.name}-image-${index}`} className={classNames('mx-1.5 h-full w-full sm:mx-1')}>
                <div className="h-full w-full">
                  <ResponsiveImage image={card.props.image} widths={{ xs: 300 }} heights={{ xs: 401 }} />
                </div>
                {!hideCaptions && (
                  <p
                    className={classNames(
                      'mt-3 line-clamp-2 min-h-8 font-gotham text-xs font-n-light leading-[normal]',
                      dark ? 'text-white' : 'text-black',
                    )}
                  >
                    {card.props.headingTitle}
                  </p>
                )}
              </div>
            ))}
          </Flicking>
        </div>

        <CarouselPagination
          carousel={carousel}
          className={classNames(
            'mt-[23px] min-h-12 w-[148px] sm:w-[468px] [&_.btn]:hidden [&_.btn]:sm:block [&_.progress-container]:max-w-[148px]',
            seasonsCount === 3 ? 'lg:w-[227px] lg:gap-0 [&_.progress-container]:lg:w-[83px]' : '',
          )}
          dark={!dark}
          animate={false}
        />

        {season.primaryCta && (
          <Button
            link={{ ...season.primaryCta, text: t('general.$exploreSeason', { season: season.title }) }}
            className={classNames('btn secondary mt-s sm:mt-[54px] lg:mt-10', !dark && 'dark')}
          />
        )}
      </div>
    </div>
  );
};

export default SeasonCard;
